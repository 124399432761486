import EDocViewer from 'components/base/CRFileViewer/viewer/EDocViewer';
import EDocClientBottomFloating from 'components/ui/EDoc/EDocClientBottomFloating';
import EDocClientButton from 'components/ui/EDoc/EDocClientButton';
import EDocDialogs from 'components/ui/EDoc/EDocDialogs';
import EDocRecordWriteForm, { RecordWriteFormList } from 'components/ui/EDoc/EDocRecordWriteForm';
import useEDocClientPage from 'lib/hook/eDoc/useESignInfoPage';
import { useConfirmEDocClient, useConfirmEDocManager } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import React, { useMemo } from 'react';
import { ESignStateCd } from 'types/api/eDoc';

interface Props {
	onConfirm: () => void;
}

function ESignInfoConfirmStep({ onConfirm }: Props) {
	const { showDialog, hideDialog } = useDialog();
	const { eDocInfo, authInfo, setEDocConfirmResult, isErp } = useEDocClientPage();
	const { mutateAsync: confirmEDocClient } = isErp
		? useConfirmEDocManager()
		: useConfirmEDocClient();

	const parsedEDocParamValue = useMemo(
		() => JSON.parse(eDocInfo?.edocPreviewResponse.data.edocParam.edocParamValue || '""'),
		[eDocInfo],
	);

	const isSubmittable = eDocInfo?.esignStateCd === ESignStateCd.OPENED;

	const openConfirmModal = () => {
		const onClickConfirm = async () => {
			// 내부적으로 로직 이슈가 있을때
			if (!authInfo) {
				hideDialog();
				onConfirm();
				return;
			}
			// 응답을 저장하고 다음단계로 이동.
			const res = await confirmEDocClient({
				sendUuid: authInfo.sendUuid,
				birthDt: authInfo.birthDt,
			});
			if (res) {
				setEDocConfirmResult({ message: res.message, code: res.code });
			}
			hideDialog();
			onConfirm();
		};

		const SubmitModal = isErp ? EDocDialogs.EDocConfirm : EDocDialogs.EDocSubmit;

		showDialog(({ hideDialog }) => (
			<SubmitModal
				documentName={eDocInfo?.edocPreviewResponse.edocNm || '전자문서'}
				onSubmit={onClickConfirm}
				onCancel={hideDialog}
			/>
		));
	};

	// TODO 유효하지 않은 형식 예외처리
	if (!parsedEDocParamValue || !eDocInfo?.edocPreviewResponse.paperTypeCd) {
		return null;
	}
	const { crfeNm, params } = parsedEDocParamValue;

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{RecordWriteFormList.includes(eDocInfo.edocPreviewResponse.paperTypeCd) ? (
				<EDocRecordWriteForm
					paperTypeCd={eDocInfo.edocPreviewResponse.paperTypeCd}
					crfeNm={crfeNm}
					params={params}
					onConfirm={onConfirm}
					isSubmittable={isSubmittable}
				/>
			) : (
				<>
					<EDocViewer
						showCustomHeader
						viewerMode='fullPage'
						style={{ height: `${window.innerHeight - 128}px` }}
						crfeName={crfeNm}
						params={params}
					/>
					{isSubmittable && (
						<EDocClientBottomFloating>
							<EDocClientButton.Submit onClick={openConfirmModal} />
						</EDocClientBottomFloating>
					)}
				</>
			)}
		</>
	);
}
export default ESignInfoConfirmStep;
