import React, { useEffect } from 'react';
import EDocViewer from 'components/base/CRFileViewer/viewer/EDocViewer';
import CRDialog from 'components/base/CRDialog';
import useDialog from 'lib/hook/util/useDialog';
import { useConfirmEDoc, useOpenEDoc } from 'lib/hook/react-query/query/edoc';
import { EDocParamValue } from 'types/api/eDoc';
import { useMyAccountInfo } from 'lib/hook/react-query';
import CRSpinner from 'components/base/CRSpinner';
import { Toast } from 'components/base/CRToast';
import CRButton from 'components/base/CRButton';
import { ResponseCode } from 'types/api/base';
import * as S from './styles';

interface Props {
	viewerType?: 'eform' | 'report';
	title?: string;
	dialogType?: 'L' | 'M' | 'S';
	esignId?: number;
	onSuccess?: () => void;
}

export default function EDocConfirmDialog({
	viewerType = 'report',
	title,
	dialogType = 'M',
	esignId,
	onSuccess,
}: Props) {
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data, mutate: openEdoc } = useOpenEDoc();
	const { mutate: confirmEDoc } = useConfirmEDoc();
	const dialog = useDialog();

	const handleClickConfirm = () => {
		if (esignId && myAccountInfo?.centerId) {
			confirmEDoc(
				{
					esignId,
					centerId: myAccountInfo.centerId,
				},
				{
					onSuccess(data) {
						if (data.code === ResponseCode.SUCCESS) {
							onSuccess?.();
							dialog.hideDialog();
						}
					},
				},
			);
		}
	};

	useEffect(() => {
		if (esignId && myAccountInfo?.centerId) {
			openEdoc(
				{ esignId, centerId: myAccountInfo.centerId },
				{
					onSuccess: (e) => {
						if (e?.code !== ResponseCode.SUCCESS) {
							Toast.error('본인확인에 실패했습니다.');
							return;
						}
					},
				},
			);
		}
	}, []);

	const paramValue: EDocParamValue =
		data?.data?.edocPreviewResponse?.data?.edocParam?.edocParamValue &&
		JSON.parse(data.data.edocPreviewResponse?.data.edocParam.edocParamValue);

	return (
		<CRDialog
			type={dialogType}
			onClickClose={dialog.hideDialog}
			title={title}
			body={
				<S.Container>
					{paramValue?.crfeNm && paramValue?.params ? (
						<EDocViewer
							viewerMode='fullPage'
							viewerType={viewerType}
							dataType='json'
							crfeName={paramValue.crfeNm}
							params={paramValue.params}
							showMenu
						/>
					) : (
						<CRSpinner />
					)}
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' onClick={() => dialog.hideDialog()}>
						취소
					</CRButton.Default>
					<CRButton.Default onClick={handleClickConfirm}>확인 완료</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
