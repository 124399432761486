import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	display: row;
	align-items: stretch;
	width: 100%;
	height: 100%;
	max-height: 100vh;
	overflow: hidden;
`;

export const CurrentViewerContainer = styled.div`
	flex: 1;
	/* height: 100%; */
	max-height: 100vh;
	border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	overflow: auto;
`;

export const CurrentFileViewerControllerContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 5.6rem;
	padding: 0 2.4rem;
	box-sizing: border-box;
`;

export const CurrentFileNameText = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
	flex: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const PaginationControl = styled.div`
	display: flex;
	flex-direction: row;
	margin: 0rem 2.4rem;
	white-space: nowrap;
`;

export const PaginationCurrentPageControl = styled.input`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
	width: 3.2rem;
	text-align: center;
	border: none;
	outline: none;
	padding: 0;
	margin: 0;
	background: transparent;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};
`;

export const PaginationTotalPageText = styled.span`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
`;

// TODO: Controller -> Control로 네이밍 변경
export const ControllerContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
`;

export const ControllerButton = styled.button`
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	outline: none;
	border: none;
	background: none;
	padding: 0;
	margin: 0;
`;

export const ControllerIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	padding: 1.6rem;
`;

export const ControllerDivider = styled.div`
	width: 0.1rem;
	height: 3rem;
	background: ${(props) => props.theme.colors.gray90};
`;

export const CurrentPDFViewerContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	overflow: hidden;
`;

export const CurrentImageViewerContainer = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	overflow: overlay;
`;

export const CurrentImageView = styled.img`
	display: block;
	width: 100%;
	object-fit: contain;
`;

export const CurrentFileViewer = styled.canvas`
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

export const FileListContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 32rem;
	max-width: 32rem;
	min-width: 32rem;
	height: 100%;
	max-height: 100vh;
	box-sizing: border-box;
	overflow: hidden;
`;

export const FileAddContainer = styled.div`
	padding: 2.4rem 2.4rem 0rem 2.4rem;
`;

export const FileAddButton = styled.button`
	display: flex;
	width: 100%;
	padding: 1.4rem 0rem;
	background: ${(props) => props.theme.colors.gray95};
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	cursor: pointer;
`;

export const FileAddButtonIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const EmptyFileAddButtonIcon = styled(FileAddButtonIcon)`
	margin-bottom: 0.8rem;
`;

export const FileAddButtonText = styled.span`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};
`;

export const FileListDivider = styled.div`
	width: 100%;
	height: 0.1rem;
	background: ${(props) => props.theme.colors.gray90};
	margin: 1.6rem 0rem;
`;

export const FileListTitle = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
	margin-bottom: 1.6rem;
	padding: 0rem 2.4rem;
`;

export const FileItemListContainer = styled.div`
	flex: 1;
	display: block;
	overflow-y: auto;
`;

export const FileItemListScrollContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 0rem 2.4rem;
	box-sizing: border-box;
	margin-bottom: 19.6rem;
`;

export const FileItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 19.6rem;
	cursor: pointer;

	& + & {
		margin-top: 1.6rem;
	}
`;

export const PDFItemThumbnail = styled.div<{ selected?: boolean }>`
	display: flex;
	width: 100%;
	height: 16rem;
	background: ${(props) => props.theme.colors.gray95};
	border-radius: ${(props) => props.theme.radius.radius01};
	margin-bottom: 0.8rem;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	${(props) =>
		props.selected
			? css`
					border: 0.2rem solid ${props.theme.colors.primary60};
					border-radius: ${props.theme.radius.radius01};
			  `
			: css`
					border: 0.2rem solid transparent;
					border-radius: ${props.theme.radius.radius01};
			  `}
`;

export const FileItemThumbnail = styled.img<{ selected?: boolean }>`
	width: 100%;
	height: 16rem;
	background: ${(props) => props.theme.colors.gray95};
	border-radius: ${(props) => props.theme.radius.radius01};
	margin-bottom: 0.8rem;
	object-fit: contain;

	${(props) =>
		props.selected
			? css`
					border: 0.2rem solid ${props.theme.colors.primary60};
					border-radius: ${props.theme.radius.radius01};
			  `
			: css`
					border: 0.2rem solid transparent;
					border-radius: ${props.theme.radius.radius01};
			  `}
`;

export const FileItemMetadataContainer = styled.div``;

export const FileItemNameText = styled.div`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const EmptyFileViewerContainer = styled.div<{ highlight?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 2.4rem;
	box-sizing: border-box;
`;

export const EmptyFileViewerContentContainer = styled.div<{ highlight?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: ${(props) =>
		props.highlight ? props.theme.colors.gray95 : props.theme.colors.gray99};
	border: 0.1rem dashed ${(props) => props.theme.colors.gray90};
	border-radius: ${(props) => props.theme.radius.radius01};
	padding: 6.4rem;
	box-sizing: border-box;
`;

export const EmptyFileViewerContent = styled.div<{ highlight?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

export const EmptyFileViewerDescription = styled.div`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray60};
	margin-bottom: 0.8rem;
`;

export const EmptyFileUploadButton = styled.button`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10};
	padding: 0rem 0.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.4rem;
	outline: none;
	background: transparent;
	cursor: pointer;
`;

export const ViewerContainer = styled.div`
	position: relative;
	width: 100%;
	height: calc(90vh - 15rem);
`;

export const EDocViewerHeader = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 76.8rem;
	margin: 0 auto;
	padding: 0.8rem 0.6rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fafafa;
`;

export const EmptyBox = styled.div`
	height: 5.6rem;
`;
