import React, { ChangeEvent, useMemo } from 'react';
import CRSwitch from 'components/base/Selections/CRSwitch';
import { Controller, useFormContext } from 'react-hook-form';
import CRInput from 'components/base/CRInput';
import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import * as S from '../styles';
import { EDocFormRow, EDocFormTableRoot } from '../EDocFormTable';
import { VisitBathRecordFormFields } from '../types';

interface Props {
	disabled?: boolean;
}

export function VisitBathRecordForm({ disabled }: Props) {
	const { control, setValue, watch } = useFormContext<VisitBathRecordFormFields>();

	const toggleOtherCheckBoxes = (e: ChangeEvent<HTMLInputElement>, count = 2) => {
		const { name, checked } = e.target;
		if (!checked) return;
		const fieldKey = e.target.name.substring(0, name.length - 1);
		const keyGroup = [];
		for (let i = 1; i <= count; i += 1) {
			if (fieldKey === 'noCarChk' || fieldKey === 'carChk') {
				keyGroup.push(`noCarChk${i}` as keyof VisitBathRecordFormFields);
				keyGroup.push(`carChk${i}` as keyof VisitBathRecordFormFields);
			} else {
				keyGroup.push(`${fieldKey}${i}` as keyof VisitBathRecordFormFields);
			}
		}
		keyGroup.forEach((key) => {
			if (key === name) {
				setValue(key as keyof VisitBathRecordFormFields, checked ? '1' : '0');
			} else {
				setValue(key as keyof VisitBathRecordFormFields, checked ? '0' : '1');
			}
		});
	};

	const isCarNumInputDisabled = useMemo(() => {
		const disabled = Boolean(
			watch('noCarChk1') === '1' ||
				watch('noCarChk2') === '1' ||
				watch('noCarChk3') === '1' ||
				(watch('carChk1') === '0' && watch('carChk2') === '0'),
		);
		if (disabled) {
			setValue('carNumber', '');
		}
		return disabled;
	}, [
		watch('noCarChk1'),
		watch('noCarChk2'),
		watch('noCarChk3'),
		watch('carChk1'),
		watch('carChk2'),
	]);

	return (
		<S.FormContainer>
			<S.SectionWrapper>
				<S.Container1>
					<S.Container2>
						<CRText typography='h3' color='red'>
							1) 이동서비스
						</CRText>
						<EDocFormTableRoot>
							<CRTable.RowTitle
								textAlign='left'
								items={[
									{ width: '35%', colSpan: 1, children: '서비스 내용' },
									{ width: '65%', children: '' },
								]}
							/>
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='차량 미이용' />,
									inputs: (
										<FlexContainer direction='column' align='flex-start' width='100%' gap='1rem'>
											<Controller
												name='noCarChk1'
												control={control}
												render={({ field }) => (
													<CRCheckBox
														{...field}
														disabled={disabled}
														onChange={(e) => toggleOtherCheckBoxes(e, 3)}
														checked={field.value === '1'}
														checkBoxType='radio'>
														<CRText text='가정내(이동식) 욕조' />
													</CRCheckBox>
												)}
											/>
											<Controller
												name='noCarChk2'
												control={control}
												render={({ field }) => (
													<CRCheckBox
														{...field}
														disabled={disabled}
														onChange={(e) => toggleOtherCheckBoxes(e, 3)}
														checked={field.value === '1'}
														checkBoxType='radio'>
														<CRText text='장기요양기관' />
													</CRCheckBox>
												)}
											/>
											<Controller
												name='noCarChk3'
												control={control}
												render={({ field }) => (
													<CRCheckBox
														{...field}
														disabled={disabled}
														onChange={(e) => toggleOtherCheckBoxes(e, 3)}
														checked={field.value === '1'}
														checkBoxType='radio'>
														<CRText text='대중목욕탕' />
													</CRCheckBox>
												)}
											/>
										</FlexContainer>
									),
								}}
							/>
							<EDocFormRow
								item={{
									description: <CRText typography='bodyB' text='차량이용' />,
									inputs: (
										<S.SelectionFlexColContainer>
											<Controller
												name='carNumber'
												control={control}
												render={({ field }) => (
													<CRInput.RectangleInput
														{...field}
														maxLength={10}
														placeholder='차량번호를 입력해주세요'
														disabled={isCarNumInputDisabled || disabled}
														typography='body'
														color='gray60'
														width='100%'
													/>
												)}
											/>
											<Controller
												name='carChk1'
												control={control}
												render={({ field }) => (
													<CRCheckBox
														{...field}
														disabled={disabled}
														onChange={(e) => toggleOtherCheckBoxes(e, 3)}
														checked={field.value === '1'}
														checkBoxType='radio'>
														<CRText text='차량내목욕 여부' />
													</CRCheckBox>
												)}
											/>
											<Controller
												name='carChk2'
												control={control}
												render={({ field }) => (
													<CRCheckBox
														{...field}
														disabled={disabled}
														onChange={(e) => toggleOtherCheckBoxes(e, 3)}
														checked={field.value === '1'}
														checkBoxType='radio'>
														<CRText text='가정내목욕 여부' />
													</CRCheckBox>
												)}
											/>
										</S.SelectionFlexColContainer>
									),
								}}
							/>
						</EDocFormTableRoot>
						<EDocFormTableRoot>
							<CRTable.RowTitle textAlign='left' items={[{ children: '제공방법' }]} />
							<EDocFormRow
								item={{
									inputs: (
										<FlexContainer width='100%' justify='space-between'>
											<Controller
												name='provideChk1'
												control={control}
												render={({ field }) => (
													<CRCheckBox
														{...field}
														disabled={disabled}
														onChange={(e) => toggleOtherCheckBoxes(e, 3)}
														checked={field.value === '1'}
														checkBoxType='radio'>
														<CRText text='전신 입욕' />
													</CRCheckBox>
												)}
											/>
											<Controller
												name='provideChk2'
												control={control}
												render={({ field }) => (
													<CRCheckBox
														{...field}
														disabled={disabled}
														onChange={(e) => toggleOtherCheckBoxes(e, 3)}
														checked={field.value === '1'}
														checkBoxType='radio'>
														<CRText text='침상' />
													</CRCheckBox>
												)}
											/>
											<Controller
												name='provideChk3'
												control={control}
												render={({ field }) => (
													<CRCheckBox
														{...field}
														disabled={disabled}
														onChange={(e) => toggleOtherCheckBoxes(e, 3)}
														checked={field.value === '1'}
														checkBoxType='radio'>
														<CRText text='목욕의자' />
													</CRCheckBox>
												)}
											/>
										</FlexContainer>
									),
								}}
							/>
						</EDocFormTableRoot>
					</S.Container2>
				</S.Container1>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<S.Container2>
					<CRText typography='h3' color='red'>
						2) 상태 확인
					</CRText>
					<EDocFormTableRoot>
						<CRTable.RowTitle
							textAlign='left'
							items={[
								{ width: '65%', children: '상태 확인(목욕 전)' },
								{ width: '35%', children: '' },
							]}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='배뇨, 배변' />,
								inputs: (
									<Controller
										name='beforeBathChk1'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='욕창' />,
								inputs: (
									<Controller
										name='beforeBathChk2'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='얼굴색, 피부색' />,
								inputs: (
									<Controller
										name='beforeBathChk3'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
					</EDocFormTableRoot>
					<EDocFormTableRoot>
						<CRTable.RowTitle
							textAlign='left'
							items={[
								{ width: '65%', children: '상태 확인(목욕 후)' },
								{ width: '35%', children: '' },
							]}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='얼굴색, 피부색' />,
								inputs: (
									<Controller
										name='afterBathChk1'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='몸단장' />,
								inputs: (
									<Controller
										name='afterBathChk2'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
						<EDocFormRow
							item={{
								description: <CRText typography='bodyB' text='주변정리' />,
								inputs: (
									<Controller
										name='afterBathChk3'
										control={control}
										render={({ field }) => (
											<CRSwitch
												{...field}
												disabled={disabled}
												onChange={(e) => {
													field.onChange(e ? '1' : '0');
												}}
												value={field.value === '1'}
												type='icon'
											/>
										)}
									/>
								),
							}}
						/>
					</EDocFormTableRoot>
				</S.Container2>
			</S.SectionWrapper>
			<S.Container2>
				<CRText typography='h3' color='red'>
					3) 특이사항
				</CRText>
				<Controller
					name='specialContent'
					control={control}
					render={({ field }) => {
						const maxLength = 120;
						return (
							<CRInput.TextArea
								{...field}
								disabled={disabled}
								placeholder='특이사항을 작성해주세요'
								status={field?.value?.length > maxLength - 1 ? 'error' : 'default'}
								addOnBottom={`${maxLength}자까지 입력이 가능합니다.`}
								maxLength={maxLength}
							/>
						);
					}}
				/>
			</S.Container2>
		</S.FormContainer>
	);
}
