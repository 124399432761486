import CRTable from 'components/base/CRTable';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import CRTableHeader from 'components/base/CRTableHeader';
import { CheckOption } from 'components/base/Selections/type';
import { Filter } from 'types/view/filter';
import MonitoringDetailTable from 'components/domain/table/MonitoringDetailTable';
import CRButton from 'components/base/CRButton';
import {
	MonitoringDetailsDTO,
	ResponseCode,
	SyncMonitoringIndividualRequest,
	UpdateMonitoringDetailsCompleteStatusRequest,
} from 'types/api';
import useSideModal from 'lib/hook/util/useSideModal';
import MonitoringSideModal from 'components/domain/sideModal/MonitoringSideModal';
import CRSyncMenu from 'components/base/CRSyncMenu';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { useMonitoringDetails } from 'lib/hook/react-query/query/monitoring';
import { useLocation } from 'react-router-dom';
import { displayPadTime, queryStringToObject } from 'lib';
import CRSpinner from 'components/base/CRSpinner';
import { useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { commonCodeAdapter } from 'lib/adapter/common';
import dayjs from 'dayjs';
import {
	useSyncMonitoringIndividual,
	useUpdateWorkConfirmStatus,
	useUpdateWorkDuplicationStatus,
} from 'lib/hook/react-query/mutation/monitoring';
import { Toast } from 'components/base/CRToast';
import { endpoint } from 'lib/service/Api/endpoint';
import RdPopover from 'components/ui/radix/popover/RdPopover';
import Assets from 'assets';
import { CRText } from 'components/base/CRText';
import Colors from 'common/colors';
import useDialog from 'lib/hook/util/useDialog';
import { MonitoringDetailsCompleteStatusDialog } from 'components/domain/dialog/MonitoringDetailsCompleteStatusDialog';
import * as S from './styles';

export enum ServiceKindCds {
	가족요양 = 'CMN083.10',
	방문요양 = 'CMN083.11',
	인지요양 = 'CMN083.12',
	방문목욕 = 'CMN083.20',
	가족목욕 = 'CMN083.21',
	차량내목욕 = 'CMN083.22',
	가정내목욕 = 'CMN083.23',
	방문간호 = 'CMN083.30',
}

export enum ChargeTypeCds {
	정상_태그 = 'CMN201.10',
	기록지_태그 = 'CMN201.20',
	기록지_미태그 = 'CMN201.30',
}

export enum MonitoringCheckMode {
	근무확인변경 = '근무확인변경',
	근무중복확인변경 = '근무중복확인변경',
}

export default function MonitoringDetailPage() {
	const [isOpen, setOpen] = useState(false);
	const [currentRow, setCurrentRow] = useState<MonitoringDetailsDTO>();
	const { data: commonCodes } = useCommonCodes({ comCdGroupNms: ['CMN201'] }, commonCodeAdapter);
	const chargeTypeOptions = commonCodes?.CMN201 || [];
	const location = useLocation();
	const params = useMemo(() => queryStringToObject(location.search), [location.search]);
	const [currentFilter, setCurrentFilter] = useState<{
		[key: string]: CheckOption[];
	}>({});
	const { showDialog } = useDialog();
	const { showSideModal, hideSideModal } = useSideModal();
	const hasPermission = useHasPermission('센터장');
	const [checkMode, setCheckMode] = useState<MonitoringCheckMode>();
	const [checkedRows, setCheckedRows] = useState<MonitoringDetailsDTO[]>([]);
	const { data: myAccountInfo } = useMyAccountInfo();
	const {
		data: monitoringDetails,
		isLoading: monitoringDetailsLoading,
		refetch,
	} = useMonitoringDetails({
		centerId: params.centerId,
		serviceYm: params.serviceYm,
		recipientId: params.recipientId,
		employeeId1: params.employeeId1,
		employeeId2: params.employeeId2,
		serviceTypeCd: params.serviceTypeCd,
	});
	const { mutate: syncMonitoringIndividual, isLoading: syncLoading } = useSyncMonitoringIndividual(
		async (client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				await client.invalidateQueries([endpoint.getMonitoringDetails.key]);
				Toast.success(
					'데이터 갱신 요청을 하였습니다. 잠시 뒤 자동화 업데이트 후 반영될 예정입니다.',
				);
			} else {
				Toast.error(
					returnData?.message ||
						'데이터 갱신 요청에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.',
				);
			}
		},
	);

	const { mutate: updateWorkConfirmStatus } = useUpdateWorkConfirmStatus((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			if (returnData.data?.successCnt) {
				if (returnData.data?.totCnt === returnData.data?.successCnt) {
					Toast.success(`총 ${returnData.data?.successCnt}건을 확인 완료로 변경하였습니다.`);
					client.invalidateQueries([endpoint.getMonitoringDetailItem.key]);
					client.invalidateQueries([endpoint.getMonitoringDetails.key]);
				} else if (returnData.data?.totCnt !== returnData.data?.successCnt) {
					Toast.warning(
						`${returnData.data?.successCnt}건 변경 완료, ${returnData.data?.failureCnt}건 실패하였습니다.`,
					);
				}
				refetch();
			} else {
				Toast.error('RFID(기록지)시간이 없어 완료로 변경할 수 없습니다.');
			}
		} else {
			Toast.error('완료로 변경에 실패했습니다. 잠시 후 다시 시도해 주시기 바랍니다.');
		}
		setCheckMode(undefined);
		setCheckedRows([]);
	});
	const { mutate: updateWorkDuplicationStatus } = useUpdateWorkDuplicationStatus(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				if (returnData.data?.successCnt) {
					if (returnData.data?.totCnt === returnData.data?.successCnt) {
						Toast.success(`총 ${returnData.data?.successCnt}건을 확인 완료로 변경하였습니다.`);
						client.invalidateQueries([endpoint.getMonitoringDetailItem.key]);
						client.invalidateQueries([endpoint.getMonitoringDetails.key]);
					} else if (returnData.data?.totCnt !== returnData.data?.successCnt) {
						Toast.warning(
							`${returnData.data?.successCnt}건 변경 완료, ${returnData.data?.failureCnt}건 실패하였습니다.`,
						);
					}
					refetch();
				} else {
					Toast.error('RFID(기록지)시간이 없어 완료로 변경할 수 없습니다.');
				}
			} else {
				Toast.error('완료로 변경에 실패했습니다. 잠시 후 다시 시도해 주시기 바랍니다.');
			}

			setCheckMode(undefined);
			setCheckedRows([]);
		},
	);

	const detailsData = useMemo(
		() =>
			(monitoringDetails || [])
				.filter((item) => {
					const statusFilterExists = currentFilter?.statusFilter?.length > 0;

					// Check status filter
					if (statusFilterExists) {
						const statusMatched = currentFilter.statusFilter.some(
							(filter) => item.schedulePlanCompleteYn === filter.value,
						);
						if (!statusMatched) {
							return false;
						}
					}
					// If no filters or matches found, include the item
					return true;
				})
				.filter((item) => {
					const chargeTypeFilterExists = currentFilter?.chargeTypeFilter?.length > 0;

					// Check charge type filter
					if (chargeTypeFilterExists) {
						const chargeTypeMatched = currentFilter.chargeTypeFilter.some(
							(filter) => item.chargeTypeCd === filter.value,
						);
						if (!chargeTypeMatched) {
							return false;
						}
					}

					// If no filters or matches found, include the item
					return true;
				}),
		[monitoringDetails, currentFilter],
	);

	const handleToggleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
		if (!detailsData?.length) return;

		if (e.target.checked) {
			if (checkMode === MonitoringCheckMode.근무확인변경) {
				setCheckedRows(
					detailsData?.filter((item) => item?.workConfirmYn === false && item?.rfidStartTime),
				);
			} else {
				setCheckedRows(detailsData?.filter((item) => item?.workDuplicationCheckNeeds?.length > 0));
			}
		} else {
			setCheckedRows([]);
		}
	};

	const handleChangCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();
		const matchedItem = checkedRows.find(
			(item) => item.scheduleMonitoringId === Number(e.target.id),
		);
		if (matchedItem) {
			setCheckedRows(
				checkedRows.filter((checkedRow) => checkedRow.scheduleMonitoringId !== Number(e.target.id)),
			);
		} else {
			const newItem = detailsData.find((item) => item.scheduleMonitoringId === Number(e.target.id));
			if (newItem) {
				setCheckedRows([...checkedRows, newItem]);
			}
		}
	};

	const handleClickRow = (item: MonitoringDetailsDTO) => {
		if (item.scheduleMonitoringId === currentRow?.scheduleMonitoringId) {
			setCurrentRow(undefined);
			hideSideModal();
		} else {
			setCurrentRow(item);
			showSideModal(
				<MonitoringSideModal item={item} clearCurrentRow={() => setCurrentRow(undefined)} />,
			);
		}
	};

	const handleClickCheckModeCancel = () => {
		setCheckMode(undefined);
		setCheckedRows([]);
	};

	const renderTitle = () => {
		if (!monitoringDetails?.length) return '';
		const recipientNm = monitoringDetails?.[0]?.recipientNm;
		const longtermNo = monitoringDetails?.[0]?.longTermNo;
		const employeeNames = [...new Set(monitoringDetails.map((entry) => entry.employeeNm))].join(
			', ',
		);
		const serviceYm = monitoringDetails?.[0]?.serviceYm;
		const serviceKindNm = monitoringDetails?.[0]?.serviceKindNm;

		return `${recipientNm} 수급자(${longtermNo}) / ${employeeNames} 직원 - ${dayjs(
			serviceYm,
		).format('YYYY년 M월')} ${serviceKindNm}`;
	};

	const renderCompleteSyncDate = () => {
		if (!monitoringDetails?.length) return '';
		const syncCompleteDate = monitoringDetails?.[0]?.syncCompleteDate;
		return syncCompleteDate
			? `${dayjs(syncCompleteDate).format('YYYY.MM.DD HH:mm')} 데이터 갱신 완료`
			: '';
	};

	const handleChangeWorkStatus = () => {
		if (!checkedRows.length || !checkMode) return;
		const params = checkedRows.map((item) => ({
			centerId: item.centerId,
			serviceYm: item.serviceYm,
			recipientId: item.recipientId,
			employeeId: item.employeeId,
			serviceTypeCd: item.serviceTypeCd,
			schedulePlanStartDate: `${dayjs(item.serviceDt).format('YYYY-MM-DD')} ${displayPadTime(
				item.schedulePlanStartTime,
			)}:00`,
			schedulePlanEndDate: `${dayjs(item.serviceDt).format('YYYY-MM-DD')} ${displayPadTime(
				item.schedulePlanEndTime,
			)}:00`,
		}));
		if (checkMode === MonitoringCheckMode.근무확인변경) {
			updateWorkConfirmStatus(params);
		} else {
			updateWorkDuplicationStatus(params);
		}
	};

	const renderSendButton = () => {
		if (!checkMode) {
			return (
				<S.SendButtonContainer>
					<CRButton.Default
						type='outlined'
						palette='gray'
						size='small'
						style={{
							padding: '0 1.2rem',
						}}
						onClick={() => setCheckMode(MonitoringCheckMode.근무확인변경)}>
						근무 확인 변경
					</CRButton.Default>
					<CRButton.Default
						type='outlined'
						palette='gray'
						size='small'
						style={{
							padding: '0 1.2rem',
						}}
						onClick={() => setCheckMode(MonitoringCheckMode.근무중복확인변경)}>
						일정 중복 확인 변경
					</CRButton.Default>
					<S.Divider />
				</S.SendButtonContainer>
			);
		}

		if (checkMode === MonitoringCheckMode.근무확인변경) {
			return (
				<S.SendButtonContainer>
					<CRButton.Default
						type='outlined'
						palette='gray'
						size='small'
						style={{
							padding: '0 1.2rem',
						}}
						onClick={handleClickCheckModeCancel}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!checkedRows.length}
						palette='gray'
						size='small'
						style={{
							padding: '0 1.2rem',
						}}
						onClick={handleChangeWorkStatus}>
						완료로 변경
					</CRButton.Default>
					{checkedRows.length > 0 && (
						<S.SendButtonText>({checkedRows.length}개 선택)</S.SendButtonText>
					)}
					<S.Divider />
				</S.SendButtonContainer>
			);
		}

		return (
			<S.SendButtonContainer>
				<CRButton.Default
					type='outlined'
					palette='gray'
					size='small'
					style={{
						padding: '0 1.2rem',
					}}
					onClick={handleClickCheckModeCancel}>
					취소
				</CRButton.Default>
				<CRButton.Default
					disabled={!checkedRows.length}
					palette='gray'
					size='small'
					style={{
						padding: '0 1.2rem',
					}}
					onClick={handleChangeWorkStatus}>
					완료로 변경
				</CRButton.Default>
				{checkedRows.length > 0 && (
					<S.SendButtonText>({checkedRows.length}개 선택)</S.SendButtonText>
				)}
				<S.Divider />
			</S.SendButtonContainer>
		);
	};

	const statusFilter = useMemo(
		() =>
			({
				key: 'statusFilter',
				type: 'multi',
				placeholder: '상태',
				options: [
					{
						label: '미완료',
						value: false,
					},
					{
						label: '완료',
						value: true,
					},
				],
			} as Filter<CheckOption>),
		[],
	);

	const chargeTypeFilter = useMemo(
		() =>
			({
				key: 'chargeTypeFilter',
				type: 'multi',
				placeholder: '청구 유형',
				options: chargeTypeOptions,
			} as Filter<CheckOption>),
		[chargeTypeOptions],
	);

	const handleRefresh = () => {
		refetch();
	};

	const handleClickSync = () => {
		if (
			syncLoading ||
			!params?.centerId ||
			!params.serviceYm ||
			!params.recipientId ||
			!params.employeeId1 ||
			!params.serviceTypeCd
		)
			return;
		const param: SyncMonitoringIndividualRequest = {
			centerId: Number(params.centerId),
			serviceYm: params.serviceYm,
			recipientId: Number(params.recipientId),
			employeeId1: Number(params.employeeId1),
			serviceTypeCd: params.serviceTypeCd,
		};

		if (params.employeeId2) {
			param.employeeId2 = Number(params.employeeId2);
		}

		syncMonitoringIndividual(param);
	};

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>일정 내역이 없습니다.</S.EmptyTitle>
				<S.EmptyDescription>
					해당 월 데이터가 아직 갱신되지 않았습니다.
					<br />
					공단 데이터 불러오기 후, 데이터 갱신 버튼을 눌러주세요.
				</S.EmptyDescription>
			</S.EmptyContainer>
		),
		[],
	);

	const onClickConfirm = (completeYn: boolean) => {
		if (!monitoringDetails?.length || !myAccountInfo?.centerId) return;
		const recipientNm = monitoringDetails?.[0]?.recipientNm;
		const serviceYm = monitoringDetails?.[0]?.serviceYm;

		const title = `확정 ${completeYn ? '완료' : '대기'}로 변경`;
		const content = `${recipientNm}님의 ${dayjs(serviceYm).format('YYYY년 M월')} 일정 전체를 확정${
			!completeYn ? ' 취소' : ''
		}합니다`;

		const param: UpdateMonitoringDetailsCompleteStatusRequest = {
			centerId: myAccountInfo.centerId,
			serviceYm: params.serviceYm,
			serviceTypeCd: params.serviceTypeCd,
			recipientId: Number(params.recipientId),
			employeeId1: Number(params.employeeId1),
			completeYn,
		};
		if (params.employeeId2) {
			param.employeeId2 = Number(params.employeeId2);
		}

		setOpen(false);
		showDialog(() => (
			<MonitoringDetailsCompleteStatusDialog title={title} content={content} param={param} />
		));
	};

	return (
		<S.Container>
			<S.TitleContainer>
				{monitoringDetails && monitoringDetails?.length > 0 && (
					<>
						<S.Title>
							{renderTitle()}
							<S.ButtonContainer>
								{hasPermission && (
									<CRSyncMenu
										title='데이터 갱신'
										isProgress={syncLoading}
										onClickSync={handleClickSync}
										showHistoryButton={false}
									/>
								)}
								{hasPermission && (
									<RdPopover
										showDivider={false}
										side='right'
										align='start'
										borderShape='small'
										sideOffset={-130}
										alignOffset={100}
										isOpen={isOpen}
										setOpen={setOpen}
										content={
											<>
												<S.DropdownItem onClick={() => onClickConfirm(true)}>
													<S.Circle $color={Colors.green} /> 완료로 변경
												</S.DropdownItem>
												<S.DropdownItem onClick={() => onClickConfirm(false)}>
													<S.Circle $color={Colors.gray90} />
													대기로 변경
												</S.DropdownItem>
											</>
										}>
										<CRButton.Default
											style={{
												display: 'flex',
												gap: '0.8rem',
												padding: '0.6rem 1.6rem 0.6rem 2.4rem',
											}}>
											<CRText typography='body' color='gray100' text='전체 확정' />
											<S.Icon
												src={
													isOpen ? Assets.icon.keyboardArrowTop : Assets.icon.keyboardArrowBottom
												}
											/>
										</CRButton.Default>
									</RdPopover>
								)}
							</S.ButtonContainer>
						</S.Title>
						<S.SubTitle>{renderCompleteSyncDate()}</S.SubTitle>
					</>
				)}
			</S.TitleContainer>
			<CRTable.BackBlind
				containerStyle={{
					height: 'calc(100vh - 14.5rem)',
				}}>
				<S.TableContainer>
					<CRTableHeader
						currentFilter={currentFilter}
						stickyMode
						filters={[statusFilter, chargeTypeFilter]}
						hideSearchButton
						setCurrentFilter={setCurrentFilter}
						onRefresh={handleRefresh}
						showRefresh
						renderCustomFilter={renderSendButton()}
					/>
					<S.TableScrollContainer>
						{monitoringDetailsLoading ? (
							<CRSpinner />
						) : detailsData.length > 0 ? (
							<MonitoringDetailTable
								currentRow={currentRow}
								checkMode={checkMode}
								checkIds={checkedRows.map((item) => String(item.scheduleMonitoringId))}
								handleToggleCheckAll={handleToggleCheckAll}
								handleChangCheckBox={handleChangCheckBox}
								onClickRow={handleClickRow}
								items={detailsData}
							/>
						) : (
							renderEmpty
						)}
					</S.TableScrollContainer>
				</S.TableContainer>
				{detailsData?.length > 0 && (
					<S.TotalCount>
						<CRText text={`총 ${detailsData?.length}건`} typography='body' color='gray10' />
					</S.TotalCount>
				)}
			</CRTable.BackBlind>
		</S.Container>
	);
}
